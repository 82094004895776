<template>
  <main data-aos="fade-up" data-aos-duration="1000">
    <!-- hero-area start -->
    <section class="breadcrumb-bg pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-9">
            <div class="page-title">
              <h1>Advisory Board</h1>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center"
          >
            <div class="page-breadcumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Advisory
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- hero-area end -->
    <!-- team-area start -->
    <section class="team-area pt-115 pb-90">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-4 col-md-6"
            v-for="advisory in advisors_data"
            :key="advisory.id"
          >
            <div class="team-wrapper team-box-2 text-center mb-30">
              <div class="default-img" @click="openBio(advisory)">
                <!-- <img :src="advisory.image" alt="" @error="imgPlaceholder" /> -->
                <div
                  class="backgroudImg"
                  v-bind:style="{
                    backgroundImage: 'url(' + advisory.image + ')',
                  }"
                ></div>
              </div>
              <div class="team-member-info mt-35 mb-25">
                <h3>
                  <a href="" @click.prevent="openBio(advisory)">{{
                    advisory.name
                  }}</a>
                </h3>
                <h6 class="f-500 text-up-case letter-spacing pink-color">
                  {{ advisory.title }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <modal name="bio-modal" :scrollable="true" height="auto">
      <button class="close-modal" @click="$modal.hide('bio-modal')">
        <i class="fa fa-times"></i>
      </button>
      <div class="bio-modal-content">
        <div class="bio-name-wrapper mb-30">
          <div class="default-img in-modal">
            <img
              v-if="advisoryInModal.image"
              :src="advisoryInModal.image"
              alt=""
            />
          </div>
          <div class="section-title pos-rel ml-5">
            <div class="section-text pos-rel">
              <h2>{{ advisoryInModal.name }}</h2>
              <h6 class="f-500 text-up-case letter-spacing pink-color">
                {{ advisoryInModal.title }}
              </h6>
            </div>
            <div class="section-line pos-rel">
              <img src="../assets/img/shape/section-title-line.png" alt="" />
            </div>
          </div>
        </div>
        <p v-html="advisoryInModal.bio"></p>
      </div>
    </modal>
  </main>
</template>

<script>
import api from "../services/api";

export default {
  name: "advisory",
  data() {
    return {
      bioModal: false,
      advisoryInModal: [],
      advisors_data: [],
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.advisors_data = await api.getAdvisors();
    for (const value of this.advisors_data) {
      if (value.image !== null && typeof value.image === "number") {
        let img = await api.getFiles(value.image);
        if (img !== null) {
          value.image = img.data["data"]["full_url"];
        }
      }
    }
  },
  methods: {
    openBio(advisory) {
      this.$modal.show("bio-modal");
      this.advisoryInModal = advisory;
    },
    imgPlaceholder(e) {
      e.target.src = "https://via.placeholder.com/300";
    },
  },
};
</script>

<style scoped>
.breadcrumb-bg {
  background-image: url("../assets/img/medical-advisory-banner.png");
  position: relative;
}
.breadcrumb-bg::after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.72);
}
.breadcrumb-bg .container {
  position: relative;
  z-index: 2;
}
.default-img {
  height: 310px;
  cursor: pointer;
}
.bio-name-wrapper {
  display: flex;
  align-items: center;
}
.bio-name-wrapper .bio-name {
  margin-left: 25px;
}
.bio-name-wrapper .bio-name h6 {
}
.default-img.in-modal {
  height: 150px;
  width: 150px;
}
.default-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.backgroudImg {
  height: 100%;
  width: 100%;
  background-size: cover;
}
.bio-modal-content {
  padding: 30px;
}

@media (max-width: 600px) {
  .section-text h2 {
    font-size: 20px;
  }
  .section-title {
    margin-left: 10px !important;
  }
  .default-img.in-modal {
    width: 100px;
    height: 100px;
  }
}
</style>
